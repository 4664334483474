import React from "react"

function ThreeLines(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="23"
      viewBox="0 0 29 23"
    >
      <path
        fill="none"
        fillRule="evenodd"
        stroke={props.stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.68"
        d="M.531.531h27.625M.531 10.094h23.375M.531 19.656h19.657"
      ></path>
    </svg>
  )
}

export default ThreeLines
