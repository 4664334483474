import React from "react"
import "./SkipContent.scss"

const SkipContent = ({ id }) => {
  return (
    <a href={`#${id}`} title="skip to main content" className="skip-content">
      Skip to main content
    </a>
  )
}

export default SkipContent
