import { Link, StaticQuery, graphql, navigate } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Fuse from "fuse.js"
import Search from "./Search.js"

import ImageMeta from "../ImageMeta"
import HamburgerMenu from "./HamburgerMenu.js"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/pro-light-svg-icons/faSearch"

import IconSpanish from "../Icons/IconSpanish"
import IconEnglish from "../Icons/IconEnglish"

var classNames = require("classnames")

class ComMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      atTopOfPage: true,
      isAlwaysTransparent: false,
      logoChangesOnScroll: false,
      search: {
        hasSearch: true,
        isOpen: false,
        isOpening: false,
        isClosing: false,
        value: "",
        suggestions: [],
        items: [],
        searchTime: ""
      },
      ada: {
        hasADA: false
      },
      logo: {
        hasLogo: true,
        position: "center",
        fullLogo: true
      },
      hamburger: {
        form: "two-lines",
        position: "left",
        isOpen: false,
        isOpening: false,
        isClosing: false
      }
    }
    this.toggleMenu = this.toggleMenu.bind(this)
    this.toggleSearch = this.toggleSearch.bind(this)
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this)
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this)
  }

  componentDidMount() {
    window.onscroll = () => {
      if (this.props.browserStatus !== false) {
        if (window.pageYOffset !== 0) {
          this.setState({
            atTopOfPage: false
          })
        } else {
          this.setState({
            atTopOfPage: true
          })
        }
      }
    }
    if (this.state.hamburger.isOpen) {
      document.documentElement.classList.add("hamburger-menu-is-open")
    } else if (this.state.hamburger.isClosing) {
      document.documentElement.classList.remove("hamburger-menu-is-open")
    }

    let items = []
    let nodes
    if (this.props.language === "es") {
      nodes = this.props.pageData.searchIndexEs.nodes
    } else {
      nodes = this.props.pageData.searchIndex.nodes
    }

    nodes.forEach(node => {
      items.push(node.context)
    })

    this.setState(prevState => ({
      ...prevState,
      searchItems: items
    }))
  }

  componentDidUpdate() {
    if (this.state.hamburger.isClosing) {
      document.documentElement.classList.remove("hamburger-menu-is-open")
    }
  }

  componentWillUnmount() {
    window.onscroll = null
  }

  toggleMenu() {
    if (this.state.hamburger.isOpen && this.props.browserStatus !== false) {
      this.setState(
        prevState => ({
          hamburger: {
            ...prevState.hamburger,
            isClosing: !prevState.hamburger.isClosing
          }
        }),
        function () {
          setTimeout(() => {
            this.setState(prevState => ({
              hamburger: {
                ...prevState.hamburger,
                isOpen: !prevState.hamburger.isOpen,
                isClosing: !prevState.hamburger.isClosing
              }
            }))
          }, 450)
        }
      )
      // Make sure to sync this setTimeout time with the one in the .scss animation time
    } else if (
      !this.state.hamburger.isOpen &&
      this.props.browserStatus !== false
    ) {
      this.setState(prevState => ({
        hamburger: {
          ...prevState.hamburger,
          isOpen: !prevState.hamburger.isOpen,
          isOpening: !prevState.hamburger.isOpening
        }
      }))
      setTimeout(() => {
        this.setState(prevState => ({
          hamburger: {
            ...prevState.hamburger,
            isOpening: !prevState.hamburger.isOpening
          }
        }))
      }, 450)
      // Make sure to sync this setTimeout time with the one in the .scss animation time
    }
  }

  toggleSearch() {
    if (this.state.search.isOpen && this.props.browserStatus !== false) {
      this.setState(prevState => ({
        search: {
          ...prevState.search,
          isClosing: !prevState.search.isClosing
        }
      }))
      setTimeout(() => {
        this.setState(prevState => ({
          search: {
            ...prevState.search,
            isOpen: !prevState.search.isOpen,
            isClosing: !prevState.search.isClosing
          }
        }))
      }, 450)
      // Make sure to sync this setTimeout time with the one in the .scss animation time
    } else if (
      !this.state.search.isOpen &&
      this.props.browserStatus !== false
    ) {
      this.setState(
        prevState => ({
          search: {
            ...prevState.search,
            isOpening: !prevState.search.isOpening,
            isOpen: !prevState.search.isOpen
          }
        }),
        function () {
          setTimeout(() => {
            this.setState(prevState => ({
              search: {
                ...prevState.search,
                isOpening: !prevState.search.isOpening
              }
            }))
          }, 1450)
        }
      )
    }
  }

  handleSearchInputChange(event) {
    if (this.props.browserStatus !== false) {
      var t0 = performance.now()
      var options
      this.setState({ value: event.target.value }, () => {
        options = {
          shouldSort: true,
          // tokenize: true,
          threshold: 0.4,
          location: 0,
          distance: 100,
          maxPatternLength: 32,
          minMatchCharLength: 1,
          keys: [
            {
              name: "searchHeading",
              weight: 0.7
            },
            {
              name: "metaDescription",
              weight: 0.3
            }
          ]
        }

        var fuse = new Fuse(this.state.searchItems, options) // "list" is the item array
        var result = fuse.search(this.state.value)
        var t1 = performance.now()
        var searchTime = parseFloat(((t1 - t0) / 1000).toFixed(2))
        if (searchTime === 0) {
          searchTime = 0.01
        }
        if (result[0]) {
          this.setState(prevState => ({
            search: {
              ...prevState.search,
              suggestions: result,
              searchTime: searchTime
            }
          }))
        } else {
          this.setState(prevState => ({
            search: {
              ...prevState.search,
              suggestions: [],
              searchTime: searchTime
            }
          }))
        }
      })
    }
  }

  handleSearchSubmit(event) {
    event.preventDefault()

    let suggestions = this.state.search.suggestions
    let searchInput = this.state.value
    let searchTime = this.state.search.searchTime
    if (this.props.language === "es") {
      navigate("/es/resultados-de-la-busqueda/", {
        state: { suggestions, searchInput, searchTime }
      })
    } else {
      navigate("/search-results/", {
        state: { suggestions, searchInput, searchTime }
      })
    }
    this.toggleSearch()
  }

  render() {
    var comMenuClass = classNames({
      "com-menu": true,
      columns: true,
      "is-mobile": true,
      "is-vcentered": true,
      "is-centered": true,
      "com-menu--scrolled": !this.state.atTopOfPage,
      transparent: this.state.isAlwaysTransparent
    })

    return (
      <StaticQuery
        query={graphql`
          query comMenuQuery {
            allDataJson(filter: { optionsItem: { eq: "nav" } }) {
              nodes {
                topLevelItems {
                  type
                  logoId
                  isPhone
                  title
                  url
                  linkTitle
                  opensFromRight
                  iconType
                }
                openMenuOrdered {
                  title
                  url
                  titleEs
                  urlEs
                  opensNewTab
                }
                openMenuUnordered {
                  title
                  url
                  titleEs
                  urlEs
                  opensNewTab
                }
                popularPages {
                  title
                  url
                  existsOnSpanish
                  titleEs
                  urlEs
                  opensNewTab
                }
              }
            }
            navEs: allDataJson(filter: { optionsItem: { eq: "navEs" } }) {
              nodes {
                topLevelItems {
                  type
                  logoId
                  isPhone
                  title
                  url
                  linkTitle
                  opensFromRight
                  iconType
                }
                openMenuOrdered {
                  title
                  url
                  titleEs
                  urlEs
                  opensNewTab
                }
                openMenuUnordered {
                  title
                  url
                  titleEs
                  urlEs
                  opensNewTab
                }
                popularPages {
                  title
                  url
                  existsOnSpanish
                  titleEs
                  urlEs
                  opensNewTab
                }
              }
            }
          }
        `}
        render={data => {
          // Define menu items from graphQL here
          let topLevelItems = data.allDataJson.nodes[0].topLevelItems
          let openMenuLeft = data.allDataJson.nodes[0].openMenuOrdered
          let openMenuRight = data.allDataJson.nodes[0].openMenuUnordered
          let popularPages = data.allDataJson.nodes[0].popularPages

          if (this.props.language === "es") {
            topLevelItems = data.navEs.nodes[0].topLevelItems
            openMenuLeft = data.navEs.nodes[0].openMenuOrdered
            openMenuRight = data.navEs.nodes[0].openMenuUnordered
            popularPages = data.navEs.nodes[0].popularPages
          }

          let searchOptions
          for (let i = 0; i < topLevelItems.length; i++) {
            if (topLevelItems[i].type === "search")
              searchOptions = topLevelItems[i]
          }
          let logoOptions
          for (let i = 0; i < topLevelItems.length; i++) {
            if (topLevelItems[i].type === "logo") logoOptions = topLevelItems[i]
          }
          let menuOptions
          for (let i = 0; i < topLevelItems.length; i++) {
            if (topLevelItems[i].type === "menu") menuOptions = topLevelItems[i]
          }

          return (
            <nav id="mainNav" className={comMenuClass}>
              <Search
                toggleSearch={this.toggleSearch}
                handleSearchInputChange={this.handleSearchInputChange}
                handleSearchSubmit={this.handleSearchSubmit}
                opensLeft={searchOptions.opensFromRight}
                isOpen={this.state.search.isOpen}
                isOpening={this.state.search.isOpening}
                isClosing={this.state.search.isClosing}
                suggestions={this.state.search.suggestions}
                language={this.props.language}
              />
              <Link
                to={this.props.language === "es" ? "/es/" : "/"}
                className="is-hidden-touch left-edge"
                activeClassName="active"
                style={{
                  // flex: "1",
                  paddingRight: "80px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <ImageMeta
                  className="logo"
                  noLazyload
                  publicId="APSC/DEV/logo"
                />
              </Link>
              <div className="menu-text-links is-hidden-touch">
                {topLevelItems.map((item, i) => (
                  <MenuTextLinks
                    key={item.type + "-" + i}
                    {...item}
                    type={item.type}
                    language={this.props.language}
                    toggleSearch={this.toggleSearch}
                    toggleMenu={this.toggleMenu}
                    isOpen={this.state.hamburger.isOpen}
                    isClosing={this.state.hamburger.isClosing}
                    isOpening={this.state.hamburger.isOpening}
                    atTopOfPage={this.state.atTopOfPage}
                    openMenuLeft={openMenuLeft}
                    openMenuRight={openMenuRight}
                    popularPages={popularPages}
                    boldMenu={this.props.boldMenu}
                  />
                ))}
              </div>
              <div
                className="is-hidden-touch right-edge"
                style={{
                  // flex: "1",
                  paddingLeft: "80px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <div className="search-icon is-hidden-touch">
                  <SearchIcon toggleSearch={this.toggleSearch} />
                </div>

                <div className="is-hidden-touch">
                  <HamburgerMenu
                    language={this.props.language}
                    toggleSearch={this.toggleSearch}
                    toggleMenu={this.toggleMenu}
                    isOpen={this.state.hamburger.isOpen}
                    isClosing={this.state.hamburger.isClosing}
                    isOpening={this.state.hamburger.isOpening}
                    atTopOfPage={this.state.atTopOfPage}
                    openMenuLeft={openMenuLeft}
                    openMenuRight={openMenuRight}
                    popularPages={popularPages}
                    boldMenu={this.props.boldMenu}
                    iconType="word"
                  />
                </div>
              </div>

              <div className="column is-hidden-desktop">
                <TopLevelItemsMobile
                  logoId={logoOptions.logoId}
                  language={this.props.language}
                  toggleSearch={this.toggleSearch}
                  toggleMenu={this.toggleMenu}
                  isOpen={this.state.hamburger.isOpen}
                  isClosing={this.state.hamburger.isClosing}
                  isOpening={this.state.hamburger.isOpening}
                  atTopOfPage={this.state.atTopOfPage}
                  openMenuLeft={openMenuLeft}
                  openMenuRight={openMenuRight}
                  popularPages={popularPages}
                  boldMenu={this.props.boldMenu}
                  iconType="word"
                />
              </div>
            </nav>
          )
        }}
      />
    )
  }
}

ComMenu.propTypes = {
  pageData: PropTypes.object,
  language: PropTypes.string,
  browserStatus: PropTypes.bool,
  boldMenu: PropTypes.bool
}

function MenuTextLinks(props) {
  if (props.type === "navTextItem") {
    if (props.isPhone) {
      return (
        <a className="menu-main-item" href={props.url}>
          {props.title}
        </a>
      )
    } else {
      return (
        <Link
          className="menu-main-item"
          to={props.url}
          activeClassName="active"
          title={props.linkTitle}
        >
          {props.title}
        </Link>
      )
    }
  } else {
    return ""
  }
}

function TopLevelItem(props) {
  if (props.type === "logo") {
    return (
      <>
        {/* <div className="column is-12 is-hidden-desktop">
        <Link to={props.language === "es" ? "/es/" : "/"} className="menu-main-item" activeClassName="active">
          <ImageMeta className="logo" publicId={props.logoId} />
        </Link>
      </div> */}
        <div className="column is-hidden-touch is-narrow">
          <Link
            to={props.language === "es" ? "/es/" : "/"}
            className="menu-main-item"
            activeClassName="active"
          >
            <ImageMeta className="logo" noLazyload publicId={props.logoId} />
          </Link>
        </div>
      </>
    )
  } else if (props.type === "navTextItem") {
    if (props.isPhone) {
      return (
        <div className="column is-hidden-touch is-hidden-touch menu-main-item-column">
          <a className="menu-main-item" href={props.url}>
            {props.title}
          </a>
        </div>
      )
    } else {
      return (
        <div className="column is-hidden-touch is-hidden-touch menu-main-item-column">
          <Link
            className="menu-main-item"
            to={props.url}
            activeClassName="active"
            title={props.linkTitle}
          >
            {props.title}
          </Link>
        </div>
      )
    }
  } else if (props.type === "search") {
    return (
      <div className="column is-hidden-touch is-1 search-icon">
        <SearchIcon toggleSearch={props.toggleSearch} />
      </div>
    )
  } else if (props.type === "menu") {
    return (
      <div className="column is-hidden-touch is-1">
        <HamburgerMenu
          toggleMenu={props.toggleMenu}
          isOpen={props.isOpen}
          isClosing={props.isClosing}
          isOpening={props.isOpening}
          atTopOfPage={props.atTopOfPage}
          language={props.language}
          openMenuLeft={props.openMenuLeft}
          openMenuRight={props.openMenuRight}
          popularPages={props.popularPages}
          boldMenu={props.boldMenu}
          iconType={props.iconType}
        />
      </div>
    )
  } else {
    return null
  }
}

function TopLevelItemsMobile(props) {
  return (
    <div className="columns is-mobile is-vcentered">
      <div className="column is-2"></div>
      <div className="column is-4">
        {/* {
          props.language === "es" ?
          <Link
                    className="menu-main-item"
          to="/"
        >
          English
        </Link>
        :
        <Link
                  className="menu-main-item"
        to={"/es/"}
      >
        Español
      </Link>
        } */}
      </div>

      <div className="column logo-column-mobile">
        <Link
          to={props.language === "es" ? "/es/" : "/"}
          className="menu-main-item"
          activeClassName="active"
        >
          <ImageMeta noLazyload className="logo" publicId={props.logoId} />
        </Link>
      </div>
      <div className="column is-2 search-icon">
        <SearchIcon
          className="is-pulled-left"
          toggleSearch={props.toggleSearch}
        />
      </div>
      <div className="column is-2 menu-column-mobile">
        <HamburgerMenu
          toggleMenu={props.toggleMenu}
          isOpen={props.isOpen}
          isClosing={props.isClosing}
          isOpening={props.isOpening}
          atTopOfPage={props.atTopOfPage}
          language={props.language}
          openMenuLeft={props.openMenuLeft}
          openMenuRight={props.openMenuRight}
          popularPages={props.popularPages}
          boldMenu={props.boldMenu}
          iconType={props.iconType}
        />
      </div>
      <div className="column is-2"></div>
    </div>
  )
}

const SearchIcon = props => {
  return (
    <div>
      <FontAwesomeIcon
        className={props.className}
        icon={faSearch}
        onClick={props.toggleSearch}
      />
    </div>
  )
}

SearchIcon.propTypes = {
  toggleSearch: PropTypes.func
}

export default ComMenu
