import React from "react"
import PropTypes from "prop-types"

function IconEnglish(props) {
  return (
    <svg
      id="prefix__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 25 25"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".prefix__st0{fill:#6d6e6f}"}</style>
      <title>{"language-icon-english"}</title>
      <g id="prefix__language-icon-english">
        <path
          id="prefix__u"
          className="prefix__st0"
          d="M12.5 0C5.6 0 0 5.6 0 12.5S5.6 25 12.5 25 25 19.4 25 12.5 19.4 0 12.5 0zm0 23.4c-6 0-10.9-4.8-10.9-10.9 0-6 4.8-10.9 10.9-10.9 6 0 10.9 4.8 10.9 10.9 0 6-4.9 10.9-10.9 10.9z"
        />
        <path
          className="prefix__st0"
          d="M11.6 10.2H7.8v1.5h3.5V13H7.8v1.9h4v1.3H6.3V8.9h5.3v1.3zM12.9 8.9h1.6l2.9 5v-5h1.4v7.2h-1.5l-3-5.1v5.1h-1.4V8.9z"
        />
      </g>
    </svg>
  )
}

export default IconEnglish
