// CMS utils
export function getClientCloudinaryMedia(fileType, keyword, antiKeyword) {
  var cldData = require("../../config.json")
  var cldImages = cldData.images.resources
  var cldImagesFinal = []

  if (fileType) {
    for (let i = 0; i < cldImages.length; i++) {
      if (cldImages[i].format === fileType) {
        cldImagesFinal.push(cldImages[i].public_id)
      }
    }
  } else if (keyword) {
    for (let i = 0; i < cldImages.length; i++) {
      if (cldImages[i].public_id.includes(keyword)) {
        if (antiKeyword && !cldImages[i].public_id.includes(antiKeyword)) {
          cldImagesFinal.push(cldImages[i].public_id)
        } else if (!antiKeyword) {
          cldImagesFinal.push(cldImages[i].public_id)
        }
      }
    }
  } else {
    for (let i = 0; i < cldImages.length; i++) {
      cldImagesFinal.push(cldImages[i].public_id)
    }
  }
  return cldImagesFinal
}

export function getPostObj(pageContext, englishNodes, spanishNodes) {
  let post
  let language

  try {
    post = setEnglishOrSpanishPost(
      pageContext.language,
      englishNodes,
      spanishNodes
    )
    language = pageContext.language
  } catch (err) {
    console.warn(err)
    post = englishNodes
    language = "en"
  }

  return { post: post, language: language }
}

function setEnglishOrSpanishPost(language, englishNodes, spanishNodes) {
  let post

  switch (language) {
    case "en":
      post = englishNodes
      break
    case "es":
      post = spanishNodes
      break
    default:
      post = englishNodes
  }

  return post
}

// General Utils
export function sortArchive(archiveArray) {
  archiveArray.sort((a, b) => (a.order > b.order ? 1 : -1))
}

export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
}

export function getMonthYearFromStrings(year, month, language) {
  var localeLanguage
  if (language === "es") {
    localeLanguage = "es-ES"
  }
  const yearInt = parseInt(year)
  const monthInt = parseInt(month) - 1
  const date = new Date(yearInt, monthInt) // 2009-11-10
  const monthStr = date.toLocaleString(localeLanguage, { month: "long" })
  return capitalizeFirstLetter(monthStr) + " " + yearInt
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function getUnique(startingArray, targetProperty, valueToCompare) {
  let properties = []
  for (let i = 0; i < startingArray.length; i++) {
    properties.push(startingArray[i][targetProperty])
  }
  if (properties.includes(valueToCompare)) {
    return false
  }
  return true
}

export function getUnrelatedReviews(targetArray, allElements, targetProperty) {
  var elementsWithTargetProperty = []
  var nonMatching = []

  elementsWithTargetProperty = getOnlyElementsWithTargetProperty(
    targetArray,
    targetProperty
  )
  nonMatching = getNonMatchingObjectsFromAllElements(
    elementsWithTargetProperty,
    allElements,
    targetProperty
  )
  return nonMatching
}

function getOnlyElementsWithTargetProperty(array, targetProperty) {
  var elementsWithTargetProperty = []
  for (let i = 0; i < array.length; i++) {
    elementsWithTargetProperty.push(array[i][targetProperty])
  }
  return elementsWithTargetProperty
}
function getNonMatchingObjectsFromAllElements(
  elementsWithTargetProperty,
  allElements,
  targetProperty
) {
  var nonMatching = []
  for (let i = 0; i < allElements.length; i++) {
    if (
      elementsWithTargetProperty.includes(allElements[i][targetProperty]) ===
      false
    ) {
      nonMatching.push(allElements[i])
    }
  }
  return nonMatching
}

export function sortReviewsByDate(reviews) {
  reviews.sort(function (a, b) {
    var aYear = a.monthYear.year
    var bYear = b.monthYear.year
    var aMonth = a.monthYear.month
    var bMonth = b.monthYear.month

    return bYear - aYear || bMonth - aMonth
  })
  return reviews
}

// Procedures functions
export function getFeaturedProceduresAndOtherProceduresObject(procedures) {
  var featuredProcedures = []
  var otherProcedures = []
  for (let i = 0; i < procedures.length; i++) {
    if (procedures[i].procedureLevel === "featured") {
      featuredProcedures.push(procedures[i])
    } else {
      otherProcedures.push(procedures[i])
    }
  }
  return { featuredProcedures, otherProcedures }
}

export function getHearFromHeadingAndBlurbObject(
  procedure,
  originalReviewCount
) {
  var heading
  var blurb
  if (isFacewallTextGeneric(procedure, originalReviewCount)) {
    heading = "Hear From Patients"
    blurb = ""
  } else {
    heading = "Hear From ".concat(procedure.nameSingular, " Patients")
    blurb = "These patients can tell you about the firsthand experience undergoing ".concat(
      procedure.nameSingular.toLowerCase(),
      " at our office."
    )
    if (procedure.nameSingular.toLowerCase() === "dental implant") {
      blurb =
        "These patients can tell you about the firsthand experience undergoing dental implant treatment at our office."
    }
  }

  return { heading, blurb }
}
export function isFacewallTextGeneric(procedure, originalReviewCount) {
  // 4 because that is the number of reviews possible in the current FixedFacewall
  if (procedure.nameSingular === null || originalReviewCount < 4) {
    return true
  }
  return false
}

export function createHtml(html) {
  return { __html: html }
}

export const toSentenceCase = str => {
  return str.replace(/\.\s+([a-z])[^\.]|^(\s*[a-z])[^\.]/g, s =>
    s.replace(/([a-z])/, s => s.toUpperCase())
  )
}
